// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { collection, getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAtpN4fynah4-z-h9g_qM3cJAdTdodVkho",
  authDomain: "lapinou-50723.firebaseapp.com",
  projectId: "lapinou-50723",
  storageBucket: "lapinou-50723.appspot.com",
  messagingSenderId: "796489902368",
  appId: "1:796489902368:web:549f4a2d7308c6d0274f45"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// utils
const db = getFirestore(app);

// collection references
const votesCollection = collection(db, "votes");
const namesCollection = collection(db, "names");

// export utils/refs
export { db, votesCollection, namesCollection };
