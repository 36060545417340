import { IonBadge, IonButton, IonContent, IonFooter, IonHeader, IonItem, IonLabel, IonList, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { getDocs, onSnapshot, query } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { namesCollection, votesCollection } from '../firebase';

const Tab2: React.FC = () => {
  const [babyNames, setBabyNames] = useState<string[] | never[]>([]);
  const [votedNames, setVotedNames] = useState<any[]>([]);
  const [statsByName, setStatsByName] = useState<any[]>([]);

  useEffect(() => {
    const remoteNames: string[] = []
    getDocs(namesCollection)
      .then((snapshot) => {
        snapshot.forEach((doc) => remoteNames.push(doc.data().name))
        setBabyNames(remoteNames)
      })
    const q = query(votesCollection);
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const remoteVotedNames: any[] = [];
      querySnapshot.forEach((doc) => {
        remoteVotedNames.push(doc.data().voterNameChoice);
      });
      setVotedNames(remoteVotedNames)
    });

    return () => unsubscribe()
  }, [])
  useEffect(() => {
    const computeStats = () => {
      const tempStats: any[] = []
      babyNames.forEach((name) => {
        if (!votedNames.includes(name)) {
          tempStats.push({ "name": name, "percent": 0 })
        } else {
          const percent = votedNames.filter((votedName) => votedName === name).length / votedNames.length * 100
          tempStats.push({ "name": name, "percent": percent })
        }
        setStatsByName(tempStats.sort((obj1, obj2) => {
          if (tempStats.filter((stat) => stat.percent !== 0).length > 0) {
            if (obj1.percent < obj2.percent) {
              return 1;
            }
            if (obj1.percent > obj2.percent) {
              return -1;
            }
            return 0;
          } else {
            if (obj1.name < obj2.name) {
              return -1;
            }
            if (obj1.name > obj2.name) {
              return 1;
            }
            return 0;
          }

        }))
      })
    }
    computeStats()
  }, [votedNames, babyNames])
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Statistiques</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding'>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Statistiques</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonList>
          {statsByName.map(({ name, percent }) => (<IonItem key={name}><IonLabel>{name}</IonLabel><IonBadge slot='end'>{Math.round(percent)}%</IonBadge></IonItem>))}
        </IonList>
      </IonContent>
      <IonFooter className="ion-no-border">
        <IonButton href='https://www.mesenvies.fr/liste-naissance?r=39&led=22999687' target='blank' expand='block'>Voir la liste de naissance</IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default Tab2;
