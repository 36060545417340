import { IonButton, IonButtons, IonCard, IonCardContent, IonCardTitle, IonContent, IonFooter, IonHeader, IonImg, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import logo from '../assets/404.png'

const Page404: React.FC = () => {

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Page non trouvée</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Page non trouvée</IonTitle>
            <IonButtons slot='end'>
            </IonButtons>
          </IonToolbar>
        </IonHeader>


        <IonCard>
          <IonCardContent className='ion-center'>
            <IonImg src={logo} />
          </IonCardContent>
        </IonCard>



      </IonContent>
      <IonFooter className="ion-no-border">
      <IonButton href='/' expand='block'>Retourner à l'accueil</IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default Page404;
